import { Tooltip } from "@mui/material";
import { CommonLibUtils, IWorksheetAdjustmentObject } from "commonlib";
import { ReactNode } from "react";
import { ITaxFilings } from "types/tax-filings";

interface IAdjustmentWrapperProps {
  objTaxFiling: ITaxFilings;
  adjustmentKey?: string;
  children: ReactNode;
}

/**
 * WorksheetTextField and AdjustmentWrapper are both made for same purpose (To show adjustment in tooltip)
 * But, Both are required as WorksheetTextField will be used for TextField and For Label/Value AdjustmentWrapper will be used
 */
export default function AdjustmentWrapper(props: IAdjustmentWrapperProps) {
  let objAdj: IWorksheetAdjustmentObject =
    props.objTaxFiling?.WorksheetAdjustment?.[props.adjustmentKey || "none"];

  let message = objAdj?.Message || "";
  if (objAdj?.SourceData?.Fields?.length) {
    message += `<table class="bordered-table">
        <tbody>
          <tr><th>Operation</th><th>Description</th><th>Amount</th></tr>
          ${objAdj.SourceData.Fields.map((field) => {
            if (!field.Amount) return "";
            return `<tr><td>${field.Operation}</td><td>${
              field.Label
            }</td><td>${CommonLibUtils.prettyPrice(field.Amount)}</td></tr>`;
          }).join("")}
          <tr><td colspan="2">Total</td><td>${CommonLibUtils.prettyPrice(
            objAdj.SourceData.TotalAmount
          )}</td></tr>
        </tbody>
      </table>`;
  }

  return (
    <Tooltip
      title={message && <div dangerouslySetInnerHTML={{ __html: message }} />}
    >
      <div
        style={{
          backgroundColor: objAdj?.BackgroundColor,
        }}
      >
        {props.children}
      </div>
    </Tooltip>
  );
}
